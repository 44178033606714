import { AppStore, PlayStore } from '../../components';
import { apps } from './../../config';

import './Apps.css';

export const Apps = () => {
	return (
		<section id="apps">
			<h3 className="title">Apps</h3>
			{apps.map(
				({
					id,
					icon,
					name,
					description,
					techs,
					androidLink,
					appleLink
				}) => (
					<a className="app" key={id}>
						<div className="app__icon">
							<img src={icon} />
						</div>
						<div className="app__details">
							<div className="app__details-text">
								<div className="app__name">{name}</div>
								<div className="app__description">
									{description}
								</div>
							</div>
							<div className="app__techs">
								{techs.map((tech) => (
									<div className="app__tech-icon">{tech}</div>
								))}
							</div>
						</div>
						<div className="app__links">
							<a
								href={androidLink}
								target="_blank"
								className="android">
								<PlayStore />
							</a>
							<a
								href={appleLink}
								target="_blank"
								className="apple">
								<AppStore />
							</a>
						</div>
					</a>
				)
			)}
		</section>
	);
};
