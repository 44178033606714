import { Github, Linkedin, Mail, Twitter } from 'lucide-react';

import { socialLinks } from './../../config';

import './Socials.css';

export const Socials = () => {
	return (
		<section id="socials">
			<a href={socialLinks.github} target="_blank">
				<Github className="h-6 w-6" />
			</a>
			<a href={socialLinks.linkedin} target="_blank">
				<Linkedin className="h-6 w-6" />
			</a>
		</section>
	);
};
