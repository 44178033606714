import { Website, App, Location } from './../types';

export const websites: Website[] = [
	{
		id: 'neat',
		icon: 'assets/neat-logo.png',
		name: 'Neat',
		description:
			'Neat is an app to help you organize your life. It handles your relationships, your calendar, your tasks, your projects and your habits. All in one software accessible from your browser.',
		techs: ['React', 'Node', 'Typescript', 'PostgressQL'],
		link: 'https://www.useneat.co/'
	}
];

export const apps: App[] = [
	{
		id: 'vape-away',
		icon: 'assets/vape-away-logo.png',
		name: 'Vape Away',
		description:
			'Vape Away is a simple app that gives you informations and encourages you throughout your journey to stop using your vape !',
		techs: ['Flutter'],
		androidLink:
			'https://play.google.com/store/search?q=vape%20away&c=apps',
		appleLink: 'https://www.apple.com/us/search/vape-away?src=globalnav'
	},
	{
		id: 'guess-10',
		icon: 'assets/guess-10-logo.png',
		name: 'Guess 10',
		description:
			'Guess 10 is a quizz game that you have to play in teams, one team answer to a question while the other one is using the app to validate answers',
		techs: ['Flutter'],
		androidLink:
			'https://play.google.com/store/apps/details?id=gps.devineuf',
		appleLink: 'https://www.apple.com/us/search/quizz?src=serp'
	}
];

export const location: Location = {
	name: 'Bali',
	link: 'https://www.google.com/search?q=bali'
};

export const nextLocation: Location = {
	name: 'Lombok',
	link: 'https://www.google.com/search?q=Lombok'
};

export const socialLinks = {
	linkedin: 'https://www.linkedin.com/in/thomasoumar',
	github: 'https://github.com/Thoumar'
};
