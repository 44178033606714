import { websites } from './../../config';

import './Websites.css';

export const Websites = () => {
	return (
		<section id="websites">
			<h3 className="title">Websites</h3>
			{websites.map(({ id, icon, name, description, techs, link }) => (
				<a className="website" key={id} href={link} target="_blank">
					<div className="website__icon">
						<img src={icon} />
					</div>
					<div className="website__details">
						<div className="website__details-text">
							<div className="website__name">{name}</div>
							<div className="website__description">
								{description}
							</div>
						</div>
						<div className="website__techs">
							{techs.map((tech) => (
								<div className="website__tech-icon">{tech}</div>
							))}
						</div>
					</div>
				</a>
			))}
		</section>
	);
};
