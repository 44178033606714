import React from 'react';

import { Me } from './sections/me/Me';
import { Socials } from './sections/Socials/Socials';
import { Apps } from './sections/Apps/Apps';
import { Websites } from './sections/Websites/Websites';
import { Navigation } from './components';

import './App.css';

const Content = ({ children }: { children?: any }) => {
	return <main className="content">{children}</main>;
};

const App = () => {
	return (
		<React.Fragment>
			<Navigation />
			<Content>
				<Me />
				<Websites />
				<Apps />
				<Socials />
			</Content>
		</React.Fragment>
	);
};

export default App;
