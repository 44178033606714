import { useState } from 'react';

import {
	UserRound,
	Earth,
	Smartphone,
	MessageCircleIcon,
	LucideArrowRight
} from 'lucide-react';

import './Navigation.css';

export const Navigation = () => {
	const [open, setOpen] = useState<boolean>(false);
	const [active, setActive] = useState<string>('');

	const handleToggleNavigation = () => {
		setOpen((prev) => !prev);
	};

	return (
		<nav className={`navigation ${open ? 'open' : ''}`}>
			<div className="menu">
				<a
					onClick={() => setActive('me')}
					className={`link${active === 'me' ? ' active' : ''}`}
					href="#me">
					<span className="link__icon">
						<UserRound height={18} width={18} />
					</span>
					<span className="link__label">Me</span>
				</a>
				<a
					onClick={() => setActive('websites')}
					className={`link${active === 'websites' ? ' active' : ''}`}
					href="#websites">
					<span className="link__icon">
						<Earth height={18} width={18} />
					</span>
					<span className="link__label">Websites</span>
				</a>
				<a
					onClick={() => setActive('apps')}
					className={`link${active === 'apps' ? ' active' : ''}`}
					href="#apps">
					<span className="link__icon">
						<Smartphone height={18} width={18} />
					</span>
					<span className="link__label">Apps</span>
				</a>
				<a
					onClick={() => setActive('socials')}
					className={`link${active === 'socials' ? ' active' : ''}`}
					href="#socials">
					<span className="link__icon">
						<MessageCircleIcon height={18} width={18} />
					</span>
					<span className="link__label">Socials</span>
				</a>
				<div>
					<button onClick={handleToggleNavigation}>
						<LucideArrowRight />
					</button>
				</div>
			</div>
		</nav>
	);
};
